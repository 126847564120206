import gql from 'graphql-tag'


export const ADD_PACK_SERVICE = gql`
mutation Mutation($service: PackServiceInput!){
    addPackService(service: $service)
}
`
export const UPDATE_PACK_SERVICE = gql`
  mutation Mutation($uid: String!, $service: PackServiceInput!){
    updatePackService(uid: $uid, service: $service)
  }
`
export const DELETE_PACK_SERVICE = gql`
mutation Mutation($uid: String!) {
    deletePackService(uid: $uid)
}
`


export const SUBSCRIBER_PACK_SERVICE= gql`
subscription Subscription {
    pack_service {
        type
        data {
            uid
            libelle
            code
            createBy
        }
    }
}
`


export const ADD_PACK = gql`
mutation Mutation($pack: PackInput!){
    addPack(pack: $pack)
}
`
export const UPDATE_PACK = gql`
  mutation Mutation($uid: String!, $pack: PackInput!){
    updatePack(uid: $uid, pack: $pack)
  }
`
export const DELETE_PACK = gql`
mutation Mutation($uid: String!) {
    deletePack(uid: $uid)
}
`


export const SUBSCRIBER_PACK= gql`
subscription Subscription {
    pack {
        type
        data {
            uid
            libelle
            code
            description
            services
            amount
            agent
            photo
            createBy
            createdAt
        }
    }
}
`


export const ADD_PACK_MODULE = gql`
mutation Mutation($module: PackModuleInput!){
    addPackModule(module: $module)
}
`
export const UPDATE_PACK_MODULE = gql`
  mutation Mutation($uid: String!, $module: PackModuleInput!){
    updatePackModule(uid: $uid, module: $module)
  }
`
export const DELETE_PACK_MODULE = gql`
mutation Mutation($uid: String!) {
    deletePackModule(uid: $uid)
}
`


export const SUBSCRIBER_PACK_MODULE= gql`
subscription Subscription {
    pack_module {
        type
        data {
            uid
            libelle
            code
            services
            amount
            photo
        }
    }
}
`
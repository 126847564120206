<template>
  <div style="margin-top: -20px;">
    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#config_user"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-user" />
          <span>Utilisateur</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_user"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-user-type-compte' }">
            Type Compte
          </router-link>
        </li>
      </ul>
    </li>
    

    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#config_pack"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-gift"></i>
          <span>Packs</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_pack"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-packs' }">
            Liste Packs
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-packs-services' }">
            Services
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-packs-modules' }">
            Modules Complémentaires
          </router-link>
        </li>
      </ul>
    </li>
    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#config_event"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-calendar-alt" />
          <span>Evenements</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_event"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-type-event' }">
            Type
          </router-link>
        </li>
      </ul>
    </li>

    <li
      class="menu"
      v-if="is_admin"
    >
      <router-link
        :to="{name: 'admin-sms'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-sms" />
          <span>SMS</span>
        </div>
      </router-link>
    </li>
    <li class="menu">
      <a
        href="#config_id"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-passport" />
          <span>Identification</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_id"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-espece' }">
            Espèces
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-race' }">
            Race
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-robe' }">
            Robe
          </router-link>
        </li>
        
        
        <li>
          <router-link :to="{name: 'admin-alimentation'}">
            Aliment
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-action-postmortem'}">
            Action Post Mortem
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-motif-deces'}">
            Motif de décès
          </router-link>
        </li>
      </ul>
    </li>
    <li class="menu">
      <a
        href="#config_sanitaire"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-h-square" />
          <span>Sanitaire</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_sanitaire"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-motif-consultation'}">
            Motif Consultation
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-mode-vie' }">
            Mode de vie
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-parasite'}">
            Type de parasite
          </router-link>
        </li>
                
        <li>
          <router-link :to="{name: 'admin-anti-parasitaire'}">
            Anti-Parasitaire
          </router-link>
        </li>

        <li>
          <router-link :to="{name: 'admin-vaccin'}">
            Vaccin
          </router-link>
        </li>

        <li>
          <router-link :to="{name: 'admin-famille-medicament'}">
            Famille Medicament
          </router-link>
        </li>

        <li>
          <router-link :to="{name: 'admin-medicament'}">
            Medicament
          </router-link>
        </li>
                
        <li>
          <router-link :to="{name: 'admin-type-maladie'}">
            Type de maladie
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-maladie'}">
            Maladie
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-analyse'}">
            Type d'analyse
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-categorie-analyse'}">
            Categorie d'analyse
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-recherche-analyse'}">
            Type de recherche
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-examen-analyse'}">
            Examen
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-pronostic'}">
            Type de pronostic
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-type-chirurgie'}">
            Type de chirurgie
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-toilettage-activity'}">
            Activité de toilettage
          </router-link>
        </li>
      </ul>
    </li>
    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#config_questionnaire"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-h-square" />
          <span>Q/R Anamnese</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_questionnaire"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-questionnaire-section'}">
            Section
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-questionnaire'}">
            Questionnaire
          </router-link>
        </li>
      </ul>
    </li>

    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#fiche_analyse"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-h-square" />
          <span>Fiche Analyse</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="fiche_analyse"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-type-fiche-analyse'}">
            Type Fiche Analyse
          </router-link>
        </li>
      
        <li>
          <router-link :to="{name: 'admin-fiche-analyse'}">
            Fiche Analyse
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-section-fiche-analyse'}">
            Section 
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-parametre-fiche-analyse'}">
            Parametre
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-reference-fiche-analyse'}">
            Reférence
          </router-link>
        </li>
      </ul>
    </li>
  </div>
</template>

<script>
import {mapGetters, mapMutations } from 'vuex'
import { ADD, DELETE, UPDATE } from '../../../constants/app'
import { SUBSCRIBER_TYPE_EVENT } from '../../../graphql/events'
import { SUBSCRIBER_PACK, SUBSCRIBER_PACK_MODULE, SUBSCRIBER_PACK_SERVICE } from '../../../graphql/pack'
export default {

    data(){
        return {

        }
    },
    apollo: {
        $subscribe: {
          type_event: {
                query: SUBSCRIBER_TYPE_EVENT,
                result({data}){
                    let m = data.type_event
                    if(m.type === ADD) this.addTypeEvent(m.data)
                    if(m.type === DELETE) this.deleteTypeEvent(m.data)
                    if(m.type === UPDATE) this.updateTypeEvent(m.data)
                }
          },
          pack_service:{
            query: SUBSCRIBER_PACK_SERVICE,
                result({data}){
                    let s = data.pack_service
                    if(s.type === ADD) this.addPackService(s.data)
                    if(s.type === DELETE) this.deletePackService(s.data)
                    if(s.type === UPDATE) this.updatePackService(s.data)
                }
          },
          pack_module:{
            query: SUBSCRIBER_PACK_MODULE,
                result({data}){
                    let m = data.pack_module
                    if(m.type === ADD) this.addPackModule(m.data)
                    if(m.type === DELETE) this.deletePackModule(m.data)
                    if(m.type === UPDATE) this.updatePackModule(m.data)
                }
          },
          pack:{
            query: SUBSCRIBER_PACK,
                result({data}){
                    let p = data.pack
                    if(p.type === ADD) this.addPack(p.data)
                    if(p.type === DELETE) this.deletePack(p.data)
                    if(p.type === UPDATE) this.updatePack(p.data)
                }
          }
        }
    },
    methods: {
      ...mapMutations({
        addTypeEvent: 'events/ADD_TYPE',
        updateTypeEvent: 'events/UPDATE_TYPE',
        deleteTypeEvent: 'events/DELETE_TYPE',

        addPackService: 'packs/ADD_SERVICE',
        updatePackService: 'packs/UPDATE_SERVICE',
        deletePackService: 'packs/DELETE_SERVICE',

        addPackModule: 'packs/ADD_MODULE',
        updatePackModule: 'packs/UPDATE_MODULE',
        deletePackModule: 'packs/DELETE_MODULE',

        addPack: 'packs/ADD_PACK',
        updatePack: 'packs/UPDATE_PACK',
        deletePack: 'packs/DELETE_PACK'
      })
       
    },
    computed: {
        ...mapGetters({
            is_admin: 'auth/is_admin',
            is_super_admin: 'auth/is_super_admin'
        })
    }
}
</script>

<style>

</style>

const state = {
    typeProducts: [],
    categories: [],
    filtres: [],
    products: [],
    productPhotos: [],
    productPrices: [],
    factures: [],
    factureItems: []
}


const getters = {
    typeProducts: state => state.typeProducts,
    categories: state => state.categories,
    filtres: state => state.filtres,
    products: state => state.products,
    productPhotos: state => state.productPhotos,
    productPrices: state => state.productPrices,
    factures: state => state.factures,
    factureItems: state => state.factureItems
}


const mutations = {
    SET_TYPE_PRODUCTS: (state, list) => {state.typeProducts = list},
    ADD_TYPE_PRODUCT: (state, type) => {state.typeProducts.push(type)},
    UPDATE_TYPE_PRODUCT: (state, type) => {state.typeProducts = state.typeProducts.filter(item => item.uid !== type.uid); state.typeProducts.push(type)},
    DELETE_TYPE_PRODUCT: (state, type) => {state.typeProducts = state.typeProducts.filter(item => item.uid !== type.uid) },


    SET_CATEGORIES: (state, list) => {state.categories = list},
    ADD_CATEGORIE: (state, categorie) => {state.categories.push(categorie)},
    UPDATE_CATEGORIE: (state, categorie) => {state.categories = state.categories.filter(item => item.uid !== categorie.uid); state.categories.push(categorie)},
    DELETE_CATEGORIE: (state, categorie) => {state.categories = state.categories.filter(item => item.uid !== categorie.uid)},

    SET_FILTRES: (state, list) => {state.filtres = list},
    ADD_FILTRE: (state, filtre) => {state.filtres.push(filtre)},
    UPDATE_FILTRE: (state, filtre) => {state.filtres = state.filtres.filter(item => item.uid !== filtre.uid); state.filtres.push(filtre)},
    DELETE_FILTRE: (state, filtre) => {state.filtres = state.filtres.filter(item => item.uid !== filtre.uid)},


    SET_PRODUCT_PHOTOS: (state, list) =>  {state.productPhotos = list},
    ADD_PRODUCT_PHOTO: (state, photo) =>  {state.productPhotos.push(photo)},
    DELETE_PRODUCT_PHOTO: (state, photo) => {state.productPhotos = state.productPhotos.filter(item => item.uid !== photo.uid)},

    SET_PRODUCTS: (state, list) => {state.products =  list},
    ADD_PRODUCT: (state, product) => {state.products.push(product)},
    DELETE_PRODUCT: (state, product) => {state.products = state.products.filter(item => item.uid !== product.uid)},
    UPDATE_PRODUCT: (state, product) => {
        state.products = state.products.filter(item => item.uid !== product.uid);
         state.products.push(product)
    },

    SET_PRODCUT_PRICES: (state, list) => {state.productPrices = list},
    ADD_PRODUCT_PRICE: (state, price) => {state.productPrices.push(price)},
    UPDATE_PRODUCT_PRICE: (state, price) => {state.productPrices = state.productPrices.filter(item => item.uid !== price.uid); state.productPrices.push(price)},
    DELETE_PRODUCT_PRICE: (state, price) => {state.productPrices = state.productPrices.filter(item => item.uid !== price.uid)},

    SET_FACTURES: (state, list) => {state.factures = list},
    ADD_FACTURE: (state, facture) => {state.factures.push(facture)},
    UPDATE_FACTURE: (state, facture) => {state.factures = state.factures.filter(item => item.uid !== facture.uid); state.factures.push(facture)},
    DELETE_FACTURE: (state, facture) => {state.factures = state.factures.filter(item => item.uid !== facture.uid)},

    SET_FACTURE_ITEMS: (state, list) => {state.factureItems = list},
    ADD_FACTURE_ITEM: (state, item) => {state.factureItems.push(item)},
    // UPDATE_FACTURE_ITEM: (state, item) => {state.factureItems = state.factureItems.filter(el => el.uid !== item.uid); state.factureItems.push(item)},
    DELETE_FACTURE_ITEM: (state, item) => {state.factureItems = state.factureItems.filter(el => el.uid !== item.uid)}   

}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
/** USER */
export const ADD_USER = "add_user"
export const DEACTIVATE_USER = "deactivate_user"
export const ACTIVATE_USER = "activate_user"
export const CONNECTED_USER = "connected_user"


/** ANIMAL */
export const ANIMAL = 'animal'

/** RDV */
export const RDV = 'rdv'
export const CANCEL_RDV = 'cancel_rdv'
export const UPDATE_RDV = 'update_rdv'
export const VALIDATE_RDV = 'validate_rdv'

export const NOTIFICATION_ICON = (type) => {
    switch(type){
        case ADD_USER: return 'la-user-plus';
        case DEACTIVATE_USER: return 'la-user-times';
        case ACTIVATE_USER: return 'la-user-check';
        case CONNECTED_USER: return 'la-user-shield'


        case ANIMAL: return 'la-dog';
        case RDV: return 'la-calendar';
        case CANCEL_RDV: return 'la-calendar-times';
        case UPDATE_RDV: return 'la-calendar-day';
        case VALIDATE_RDV: return 'la-calendar-check'


        default: return 'la-info-circle'
    }
}
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import group from './modules/group'
import events from './modules/events'
import options from './modules/options'
import identification from './modules/identification'
import sanitaire from './modules/sanitaire'
import analyse from './modules/analyse'
import notification from './modules/notification'
import market from './modules/market'
import packs from './modules/packs'


const debug = process.env.NODE_ENV !== 'production'
Vue.use(Vuex)



export default new Vuex.Store({
  state: {
    done: 0,
    selectedObject: null,
    mode: null,
    loading: false
  },
  
  getters: {
    done: state => state.done,
    selectedObject: state => state.selectedObject,
    mode: state => state.mode,
    loading: state => state.loading
  },

  mutations: {
    DONE: (state) => {
      state.done = state.done + 1
    },

    SET_SELECTED_OBJECT: (state, object) =>  {
      state.selectedObject = object
    },

    SET_MODE: (state, mode) => {
      state.mode = mode
    },
    SET_LOADING: (state, loading) => {state.loading = loading}
  },

  modules: {
    auth,
    group,
    events,
    options,
    identification,
    sanitaire,
    analyse,
    notification,
    market,
    packs
  },
  strict: debug
})

<template>
  <div>
    <li class="menu">
      <router-link
        :to="{name: 'market-products'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
            <i class="las la-store"></i>
            <span>Produits</span>
        </div>
      </router-link>
    </li>
    <li class="menu">
      <router-link
        :to="{name: 'market-products'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
            <i class="las la-shopping-cart"></i>
            <span>Commandes</span>
        </div>
      </router-link>
    </li>
    <li class="menu">
      <router-link
        :to="{name: 'market-billing'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
            <i class="las la-file-invoice"></i>
            <span>Factures</span>
        </div>
      </router-link>
    </li>
    <li class="menu" v-if="is_admin">
      <a
        href="#config_market"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-cog"></i>
          <span>Configuration</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_market"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-market-type-product' }">
            Type de produit
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-market-categorie' }">
            Categorie
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-market-filtre' }">
            Filtre
          </router-link>
        </li>
      </ul>
    </li>
  </div>
</template>

<script>
import { ADD, DELETE, UPDATE } from '../../../constants/app'
import {SUBSCRIBER_CATEGORIE, SUBSCRIBER_TYPE_PRODUCT, SUBSCRIBER_FILTRE, SUBSCRIBER_MARKET_PRODUCT_PRICE, SUBSCRIBER_FACTURE, SUBSCRIBER_FACTURE_ITEM} from '../../../graphql/market'
import {mapGetters, mapMutations} from 'vuex'
export default {
    data(){
        return {

        }
    },
    methods: {
        ...mapMutations({
            addTypeProduct: 'market/ADD_TYPE_PRODUCT',
            updateTypeProduct: 'market/UPDATE_TYPE_PRODUCT',
            deleteTypeProduct: 'market/DELETE_TYPE_PRODUCT',

            addCategorie: 'market/ADD_CATEGORIE',
            updateCategorie: 'market/UPDATE_CATEGORIE',
            deleteCategorie: 'market/DELETE_CATEGORIE',

            addFiltre: 'market/ADD_FILTRE',
            updateFiltre: 'market/UPDATE_FILTRE',
            deleteFiltre: 'market/DELETE_FILTRE',

            addProductPrice: 'market/ADD_PRODUCT_PRICE',
            updateProductPrice: 'market/UPDATE_PRODUCT_PRICE',
            deleteProductPrice: 'market/DELETE_PRODUCT_PRICE',

            addFacture: 'market/ADD_FACTURE',
            updateFacture: 'market/UPDATE_FACTURE',
            deleteFacture: 'market/DELETE_FACTURE',

            addFactureItem: 'market/ADD_FACTURE_ITEM',
            deleteFactureItem: 'market/DELETE_FACTURE_ITEM'
        })
    },  

    apollo: {
        $subscribe: {
            market_type_object: {
                query: SUBSCRIBER_TYPE_PRODUCT ,
                result({data}){
                    let tp = data.market_type_object
                    if(tp.type === ADD) this.addTypeProduct(tp.data)
                    if(tp.type === DELETE) this.deleteTypeProduct(tp.data)
                    if(tp.type === UPDATE) this.updateTypeProduct(tp.data)
                }
            },
            market_categorie: {
                query: SUBSCRIBER_CATEGORIE ,
                result({data}){
                    let c = data.market_categorie
                    if(c.type === ADD) this.addCategorie(c.data)
                    if(c.type === DELETE) this.deleteCategorie(c.data)
                    if(c.type === UPDATE) this.updateCategorie(c.data)
                }
            },
            market_filtre: {
                query: SUBSCRIBER_FILTRE ,
                result({data}){
                    let f = data.market_filtre
                    if(f.type === ADD) this.addFiltre(f.data)
                    if(f.type === DELETE) this.deleteFiltre(f.data)
                    if(f.type === UPDATE) this.updateFiltre(f.data)
                }
            },
            market_product_price: {
                query: SUBSCRIBER_MARKET_PRODUCT_PRICE ,
                result({data}){
                    let pp = data.market_product_price
                    if(pp.type === ADD) this.addProductPrice(pp.data)
                    if(pp.type === DELETE) this.deleteProductPrice(pp.data)
                    if(pp.type === UPDATE) this.updateProductPrice(pp.data)
                }
            },
            facture: {
                query: SUBSCRIBER_FACTURE ,
                result({data}){
                    let f = data.facture
                    if(f.type === ADD) this.addFacture(f.data)
                    if(f.type === DELETE) this.deleteFacture(f.data)
                    if(f.type === UPDATE) this.updateFacture(f.data)
                }
            },
            facture_item: {
                query: SUBSCRIBER_FACTURE_ITEM ,
                result({data}){
                    let f = data.facture_item
                    if(f.type === ADD) this.addFactureItem(f.data)
                    if(f.type === DELETE) this.deleteFactureItem(f.data)
                }
            },
        }
    },
    computed: {
        ...mapGetters({
            is_admin: 'auth/is_admin',
            is_super_admin: 'auth/is_super_admin',
            is_veterinaire: 'auth/is_veterinaire',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
        })
    }
}
</script>

<style>

</style>
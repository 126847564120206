<template>
  <a class="account-item" href="#">
    <div class="media align-center">
        <div class="icon-wrap">
            <i class="las font-20" :class="icon"></i>
        </div>
        <div class="media-content ml-3">
            <h6 class="font-13 mb-0 strong">{{ notification.libelle }}</h6>
            <p class="m-0 mt-1 font-10 text-muted">{{ notification.createdAt|showTime}}</p>
        </div>
    </div>
    </a>
</template>

<script>
// import { mapGetters } from 'vuex';
import { NOTIFICATION_ICON } from '../../constants/notification'
export default {
    props: {
        notification: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        icon(){
            let type = this.notification.type
            return NOTIFICATION_ICON(type)
        }
    }

}
</script>

<style>

</style>
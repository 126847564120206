const load  = (component) => {
    return () => import(`../views/${component}.vue`)
  }


export default [
    {
        path: '/admin/sms',
        name: 'admin-sms',
        component: load('Admin/User/Sms')
    },
    {
        path: '/admin/user/type-compte',
        name: 'admin-user-type-compte',
        component: load('Admin/User/TypeCompte')
    },

    {
        path: '/admin/events/type',
        name: 'admin-type-event',
        component: load('Admin/Events/Type')
    },
    
    {
        path: '/admin/identification/espece',
        name: 'admin-espece',
        component: load('Admin/Identification/Espece')
    },
    {
        path: '/admin/identification/alimentation',
        name: 'admin-alimentation',
        component: load('Admin/Identification/Alimentation')
    },
    {
        path: '/admin/identification/race',
        name: 'admin-race',
        component: load('Admin/Identification/Race')
    },
    {
        path: '/admin/identification/robe',
        name: 'admin-robe',
        component: load('Admin/Identification/Robe')
    },
    {
        path: '/admin/identification/mode-vie',
        name: 'admin-mode-vie',
        component: load('Admin/Identification/ModeVie')
    },
    {
        path: '/admin/sanitaire/questionnaire',
        name: 'admin-questionnaire',
        component: load('Admin/Sanitaire/Questionnaire/Index')
    },
    {
        path: '/admin/sanitaire/questionnaire/section',
        name: 'admin-questionnaire-section',
        component: load('Admin/Sanitaire/Questionnaire/Section')
    },
    
    /** Analyse */
    {
        path: '/admn/sanitaire/analyse/type', 
        name: 'admin-type-analyse',
        component: load('Admin/Sanitaire/Analyse/Type')
    },
    {
        path: '/admin/sanitaire/analyse/categorie',
        name: 'admin-categorie-analyse',
        component: load('Admin/Sanitaire/Analyse/Categorie')
    },
    {
        path: '/admin/sanitaire/analyse/type-recherche',
        name: 'admin-type-recherche-analyse',
        component: load('Admin/Sanitaire/Analyse/TypeRecherche')
    },
    {
        path: '/admin/sanitaire/analyse/examen',
        name: 'admin-examen-analyse',
        component: load('Admin/Sanitaire/Analyse/Examen')
    },
    {
        path: '/admin/sanitaire/type-maladie',
        name: 'admin-type-maladie',
        component: load('Admin/Sanitaire/Maladie/Type')
    },
    {
        path: '/admin/sanitaire/maladie',
        name: 'admin-maladie',
        component: load('Admin/Sanitaire/Maladie/Index')
    },
    {
        path: '/admin/sanitaire/vaccin',
        name: 'admin-vaccin',
        component: load('Admin/Sanitaire/Medicament/Vaccin')
    },
    {
        path: '/admin/sanitaire/type-parasite',
        name: 'admin-type-parasite',
        component: load('Admin/Sanitaire/Medicament/TypeParasite')
    },
    {
        path: '/admin/sanitaire/anti-parasitaire',
        name: 'admin-anti-parasitaire',
        component: load('Admin/Sanitaire/Medicament/AntiParasitaire')
    },
    {
        path: '/admin/sanitaire/medicament',
        name: 'admin-medicament',
        component: load('Admin/Sanitaire/Medicament/Index')
    },
    {
        path: '/admin/sanitaire/famille-medicament',
        name: 'admin-famille-medicament',
        component: load('Admin/Sanitaire/Medicament/Famille')
    },
    {
        path: '/admin/sanitaire/type-pronostic',
        name: 'admin-type-pronostic',
        component: load('Admin/Sanitaire/Pronostic/Type')
    }, 

    {
        path: '/admin/sanitaire/type-chirurgie',
        name: 'admin-type-chirurgie',
        component: load('Admin/Sanitaire/Chirurgie/Type')
    }, 

    
    {
        path: '/admin/sanitaire/motif-consultation',
        name: 'admin-motif-consultation',
        component: load('Admin/Sanitaire/MotifConsultation')
    },
    
    /** Fiche Analyse */
    
    {
        path: '/admin/sanitaire/fiche-analyse',
        name: 'admin-fiche-analyse',
        component: load('Admin/Sanitaire/FicheAnalyse/Fiche')
    },
    {
        path: '/admin/sanitaire/type-fiche-analyse',
        name: 'admin-type-fiche-analyse',
        component: load('Admin/Sanitaire/FicheAnalyse/Type')
    },
    {
        path: '/admin/sanitaire/section-fiche-analyse',
        name: 'admin-section-fiche-analyse',
        component: load('Admin/Sanitaire/FicheAnalyse/Section')
    },

    {
        path: '/admin/sanitaire/parametre-fiche-analyse',
        name: 'admin-parametre-fiche-analyse',
        component: load('Admin/Sanitaire/FicheAnalyse/Parametre')
    },

    {
        path: '/admin/sanitaire/reference-fiche-analyse',
        name: 'admin-reference-fiche-analyse',
        component: load('Admin/Sanitaire/FicheAnalyse/Reference')
    },
    {
        path: '/admin/sanitaire/toilettage-activity',
        name: 'admin-toilettage-activity',
        component: load('Admin/Sanitaire/Toilettage/Activity')
    },
    {
        path: '/admin/identification/motif-deces',
        name: 'admin-motif-deces',
        component: load('Admin/Identification/MotifDeces')
    },
    {
        path: '/admin/identification/action-post-mortem',
        name: 'admin-action-postmortem',
        component: load('Admin/Identification/ActionPostMortem')
    },

    /** MARKET */
    {
        path: '/admin/market/type-product',
        name: 'admin-market-type-product',
        component: load('Admin/Market/TypeProduct')
    },
    {
        path: '/admin/market/categorie',
        name: 'admin-market-categorie',
        component: load('Admin/Market/Categorie')
    },
    {
        path: '/admin/market/filtre',
        name: 'admin-market-filtre',
        component: load('Admin/Market/Filtre')
    },

    {
        path: '/admin/packs/services',
        name: 'admin-packs-services',
        component: load('Admin/Packs/Service')
    },
    {
        path: '/admin/packs',
        name: 'admin-packs',
        component: load('Admin/Packs/Index')
    },
    {
        path: '/admin/packs/modules',
        name: 'admin-packs-modules',
        component: load('Admin/Packs/Module')
    },
]